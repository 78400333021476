import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";
import EvolutionGames from "../../../../assets/images/provider/provider-logo-evolution.png";
import EzugiGames from "../../../../assets/images/provider/provider-logo-ezugi.png";
import SupernowaGames from "../../../../assets/images/provider/provider-logo-supernowa.jpg";
import QtechGames from "../../../../assets/images/provider/provider-logo-Qtech.png";
import VirtualSports from "../../../../assets/images/provider/provider-logo-virtualSports.jpg";
import VIVOGames from "../../../../assets/images/provider/provider-logo-vivo.png";
import WorldCasino from "../../../../assets/images/provider/provider-logo-worldCasino.jpg";
import XPGGames from "../../../../assets/images/provider/provider-logo-xpg.jpg";

const LiveDealer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="container">
          <div className="live-deal-section">
            <div className="pagesHeading">
              <h2 className="sectionTitle border-0">Live Dealer</h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              <li>
                <a href="/casino/evolution">
                  <img src={EvolutionGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/ezugi">
                  <img src={EzugiGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/supernowa">
                  <img src={SupernowaGames} alt="Providers Logo" />
                </a>
              </li>
              {/* <li>
                <a href="/casino/qtech">
                  <img src={QtechGames} alt="Providers Logo" />
                </a>
              </li> */}
              <li>
                <a href="/sportsbook/Cricket">
                  <img src={VirtualSports} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={VIVOGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasino} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/xpg">
                  <img src={XPGGames} alt="Providers Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default LiveDealer;
